import React, { useState } from 'react'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
import styles from './EachFAQ.module.css'

const EachFAQ = (props) => {
    const { item } = props
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div className={styles.mainWrapper}>
            <div onClick={() => setIsOpen(!isOpen)} className={styles.question}>
                <div className={styles.questionTxt}>{item?.question}</div>
                <div className={styles.icon}>
                    {isOpen === true ?
                        <BiChevronDown size={24} />
                        :
                        <BiChevronUp size={24} />
                    }
                </div>
            </div>
            {isOpen &&
                <div className={styles.answer} >
                    {item?.answer}
                </div>
            }
        </div>

    )
}

export default EachFAQ