import React from 'react';
import styles from './EachStore.module.css';

const EachStore = ({ name, address, phone, whatsapp, images }) => {
    return (
        <div className={styles.card}>
            <div className={styles.imageSlider}>
                {images.map((image, index) => (
                    <img key={index} src={image} alt={`${name} slide ${index + 1}`} className={styles.image} />
                ))}
            </div>
            <div className={styles.details}>
                <div className={styles.name}>{name}</div>
                <div className={styles.address}>{address}</div>
                <div className={styles.actions}>
                    <a href={`tel:${phone}`} className={styles.phoneButton}>
                        Call
                    </a>
                    <a
                        href={`https://wa.me/${whatsapp.replace(/[^0-9]/g, '')}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.whatsappButton}
                    >
                        WhatsApp
                    </a>
                </div>
            </div>
        </div>
    );
};

export default EachStore;
