import React, { useContext, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { BASE_URL_API } from "../References/constants";
import AuthContext from "../store/auth-context";
import styles from "./Login.module.css";

const Login = () => {
    const authCtx = useContext(AuthContext);
    const [responseTxt, setResponseTxt] = useState('')
    const [step, setStep] = useState(1); // Step 1: Phone number, Step 2: OTP
    const [phone, setPhone] = useState("");
    const [otp, setOtp] = useState("");
    const [countryCode, setCountryCode] = useState("+91");

    const handleNext = () => {
        if (phone.length === 10) {
            sendOtpHandler(true, phone)
        } else {
            alert("Please enter a valid 10-digit phone number.");
        }
    };

    const [modeOfVerification, setModeOfVerification] = useState('CARD')
    const [otpRequested, setOtpRequested] = useState(false);
    const [otpSendStatus, setOtpSendStatus] = useState('SENT'); //SENT, SENDING , INVALID_CREDENTIALS
    const [sessionKey, setSessionKey] = useState('');
    const [requestCount, setRequestCount] = useState(0);
    const [otpSentOn, setOtpSentOn] = useState('')

    const [secondsLeft, setSecondsLeft] = useState(45)
    useEffect(() => {
        const interval = setInterval(() => {
            setSecondsLeft((p) => p - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [])


    const toggleModeOfVerification = (k) => {
        setOtpRequested(false)
        setModeOfVerification(k);
    }
    const sendOtpHandler = (k, n) => {
        if (k === false) {
            setOtpRequested(false);
        } else {
            if (n !== undefined) {
                sendOtp(n);
                setSecondsLeft(45)
            } else {
                console.log("Invalid phone, try again later!")
            }
            console.log(n, "ok")
        }
    }

    const sendOtp = async (n) => {
        if (n !== 'undefined') {
            setOtpSendStatus('SENDING')
            const sendOtpResponse = await fetch(BASE_URL_API + `/auth`,
                {
                    method: `POST`,
                    body: JSON.stringify({
                        actionType: 'sendOtp',
                        phone: n,
                        requestCount: requestCount,
                        sessionKey: sessionKey
                    })
                });

            if (!sendOtpResponse.ok) {
                console.log("Something went wrong : Server Error");
                setOtpSendStatus('SENT')
                alert('failed')
            } else {
                const sendOtpRespo = await sendOtpResponse.json();
                if (sendOtpRespo?.status === 'success') {
                    setStep(2);
                    setRequestCount(sendOtpRespo?.requestCount);
                    setSessionKey(sendOtpRespo?.sessionKey);
                    setOtpSentOn(sendOtpRespo?.phone)
                    setOtpRequested(true);
                    setOtpSendStatus('SENT')
                    setResponseTxt('')
                } else {
                    setOtpSendStatus('INVALID_CREDENTIALS')
                    setResponseTxt("You have entered an invalid number, please try again with valid number.")
                    setOtpRequested(true);
                    if (sendOtpRespo.status === 'failed') {
                        // alert('it failed')
                    } else {
                        // console.log(sendOtpRespo);
                        // alert("it failed, don't know what")
                    }
                }
                console.log(sendOtpRespo)
            }
        } else {
            console.log("Invalid phone number served, please try again later.")
        }
        console.log(n)
    }

    // console.log("currentStep : ", step)
    // alert(step)


    const [responseMessage, setResponseMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handleChange = (otp) => {
        setOtp({ otp })
        if(otp.length===6) {
            // authCtx.login("heyiamthetokenprovided", "userIdentifier");
            verifyOtp(otp);
        }
    }

    const verifyOtp = async (k) => {
        console.log(k, otpSentOn)
        setIsLoading(true)
        const verifyOtpResponse = await fetch(BASE_URL_API+"/auth",
        {
            method : 'POST',
            body : JSON.stringify({
                actionType : 'verifyOtp',
                sessionKey : sessionKey,
                otpEntered : k,
                phone : otpSentOn
            })
        });

        if(!verifyOtpResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {    
            const verifyOtpRespo = await verifyOtpResponse.json();
            if(verifyOtpRespo.status==='success') {
                if(verifyOtpRespo.authToken!==null) {
                    authCtx.login(verifyOtpRespo.authToken, verifyOtpRespo.mobile)
                } else {
                    authCtx.logout()
                }
            } else {
                if(verifyOtpRespo.message==='invalidOtp') {
                    console.log("Please enter a valid otp")
                }
                setResponseMessage(verifyOtpRespo.response)
                // console.log(verifyOtpRespo)
            }
        }
        setIsLoading(false)
    }



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.container}>
                <div className={styles.card}>
                    {step === 1 && (
                        <div className={styles.phoneStep}>
                            <h2>Login</h2>
                            <div className={styles.hint}>
                                Please enter 10 digit phone number
                            </div>

                            <div className={styles.phoneInput}>
                                <select
                                    className={styles.countryCode}
                                    value={countryCode}
                                    onChange={(e) => setCountryCode(e.target.value)}
                                >
                                    <option value="+91">+91</option>
                                    <option value="+1">+1</option>
                                    <option value="+44">+44</option>
                                    <option value="+61">+61</option>
                                    <option value="+81">+81</option>
                                </select>
                                <input
                                    type="number"
                                    className={styles.phoneField}
                                    placeholder="Phone Number"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value.replace(/\D/g, ""))}
                                    maxLength="10"
                                    autoFocus
                                />
                            </div>
                            <button className={styles.nextButton} onClick={() => sendOtpHandler(true, phone)}>
                                {otpSendStatus==="SENDING" ? `Sending OTP...` : `Next` }
                            </button>
                            {/* <button className={styles.nextButton} onClick={handleNext}>
                                Next
                            </button> */}
                        </div>
                    )}

                    {step === 2 && (
                        <div className={styles.otpStep}>
                            <div className={styles.headline}>
                                We have sent a 6 digit OTP to {phone} on WhatsApp
                            </div>
                            <h2>Enter OTP</h2>
                            <OtpInput
                                value={otp.otp}
                                onChange={handleChange}
                                numInputs={6}
                                isInputNum
                                shouldAutoFocus
                                containerStyle={styles.otpContainer}
                                inputStyle={styles.otpInput}
                            />
                            <button className={styles.submitButton} onClick={() => verifyOtp(otp)}>
                                Submit OTP
                            </button>
                            <button onClick={() => setStep(1)} className={styles.cancelBtn}>
                                Cancel
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Login;
