import React, { useContext } from 'react'
import { Routes, Route } from "react-router-dom";
import EachBookingWrapper from '../Pages/Bookings/EachBookingWrapper';
import BuyCard from '../Pages/GiftCards/BuyCard';
import EachPurchasedCardDetails from '../Pages/GiftCards/EachPurchasedCardDetails';
import GcHome from '../Pages/GiftCards/GcHome';
import PaymentStatus from '../Pages/GiftCards/PaymentStatus';
import PurchasedCards from '../Pages/GiftCards/PurchasedCards';
import VerifyCards from '../Pages/GiftCards/VerifyCards';
import Home from '../Pages/Home';
import Login from '../Pages/Login';
import OurStores from '../Pages/OurLocations/OurStores';
import PriceListHome from '../Pages/PriceList/PriceListHome';
import AuthContext from '../store/auth-context';
import BottomStackNavigator from './BottomStackNavigator';
import styles from './MainNavigator.module.css'



const MainNavigator = () => {
    const authCtx = useContext(AuthContext);

    // console.log(authCtx)

    if (!authCtx.isLoggedIn) {
        return (
            <Routes>
                <Route path="*" element={<Login />}></Route>
                <Route path="/gc/verify" element={<VerifyCards />}></Route>
                <Route path="/buy/gallant-card" element={<BuyCard />}></Route>
                <Route path="/buy/cards/:identifier" element={<BuyCard />}></Route>
                <Route path="/login" element={<Login />}></Route>
                
                <Route path="/:storeIdentifier/price-list" element={<PriceListHome />}></Route>

                <Route path="/stores" element={<OurStores />}></Route>

            </Routes>
        )
    } else if (authCtx.isLoggedIn) {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainBody}>
                    <Routes>
                        <Route path="*" element={<Home />}></Route>
                        <Route path="/gift-cards" element={<GcHome />}></Route>
                        {/* <Route path="/gc/verify" element={<VerifyCards />}></Route> */}
                        <Route path="/gc/verify" element={<PurchasedCards />}></Route>
                        <Route path="/gc/my-cards" element={<PurchasedCards />}></Route>
                        <Route path="/cards/:issuedCardNumber" element={<EachPurchasedCardDetails />}></Route>
                        <Route path="/visits/:sessionIdentifier" element={<EachBookingWrapper />}></Route>
                        <Route path="/buy/gallant-card" element={<BuyCard />}></Route>
                        <Route path="/buy/cards/:identifier" element={<BuyCard />}></Route>

                        <Route path="/buy/cards/:identifier/:status/:txnId" element={<PaymentStatus />}></Route>

                        <Route path="/stores" element={<OurStores />}></Route>
                        

                        <Route path="/free-haircut" element={<BuyCard />}></Route>
                        <Route path="/:storeIdentifier/price-list" element={<PriceListHome />}></Route>
                    </Routes>
                </div>
                <div className={styles.mainFooter}>
                    <BottomStackNavigator />
                </div>
            </div>
        )
    } else {
        return (
            <Routes>
                <Route path="*" element={<Home />}></Route>
            </Routes>
        )
    }

}

export default MainNavigator