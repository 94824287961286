import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import MainLoader from '../../Components/UI/MainLoader';
import { BASE_URL_API } from '../../References/constants';
import AuthContext from '../../store/auth-context';
import styles from '../GiftCards/EachPurchasedCardDetails.module.css'
import CardDetailsTransactions from './CardDetailsTransactions';
import CardDetailsWrapper from './CardDetailsWrapper';

const EachPurchasedCardDetails = () => {
    const authCtx = useContext(AuthContext)
    const params = useParams();
    const cardIdentifier = params.issuedCardNumber;

    const [activeHeader, setActiveHeader] = useState('details')
    const [cardDetails, setCardDetails] = useState('')
    const [gettingCardDetails, setGettingCardDetails] = useState(true)

    const getCompleteCardDetails = async () => {
        setGettingCardDetails(true)
        const getCompleteCardDetailsResponse = await fetch(BASE_URL_API + "/getCompleteCardDetails",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    cardIdentifier: cardIdentifier
                })
            });

        if (!getCompleteCardDetailsResponse.ok) {
            console.log("Something went wrong :  Server Error")
        } else {
            const getCompleteCardDetailsRespo = await getCompleteCardDetailsResponse.json();
            if (getCompleteCardDetailsRespo.status === 'success') {
                setCardDetails(getCompleteCardDetailsRespo.response[0])
            } else {
                if (getCompleteCardDetailsRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                }
            }
            console.log(getCompleteCardDetailsRespo)
        }
        setGettingCardDetails(false)
    }

    useEffect(() => {
        getCompleteCardDetails()
    }, [cardIdentifier, activeHeader])

    // console.log(cardDetails)

    return (
        <div className={styles.mainWrapper}>
            <MainLoader isVisible={gettingCardDetails} />
            {gettingCardDetails === true ?
                <div className={styles.mainHeader}
                    style={{
                        animation: '0.2s cubic-bezier(0.29, 0.28, 0, 0.98) 0s 1 normal forwards running compressFromCentreToTop'
                    }}
                >
                    <div>
                        <div className={styles.cardNameIcon}>
                            MY CARDS
                        </div>
                    </div>
                    <div className={styles.animateCardNumber}
                        style={{
                            animation: '0.2s cubic-bezier(0.29, 0.28, 0, 0.98) 0s 1 normal forwards running animateCardNumber'
                        }}
                    >
                        8000  0000 0000 0001
                    </div>
                </div>
                :
                <div className={styles.mainHeader}
                    style={{
                        animation: '0.2s cubic-bezier(0.29, 0.28, 0, 0.98) 0s 1 normal forwards running compressFromCentreToTop'
                    }}
                >
                    <div>
                        <div className={styles.cardNameIcon}>
                            {cardDetails.label}
                        </div>
                    </div>
                    <div className={styles.animateCardNumber}
                        style={{
                            animation: '0.2s cubic-bezier(0.29, 0.28, 0, 0.98) 0s 1 normal forwards running animateCardNumber'
                        }}
                    >
                        {cardIdentifier}
                    </div>
                </div>
            }

            <div className={styles.subHeader}>
                <div className={activeHeader === 'details' ? styles.eachSubHeaderActive : styles.eachSubHeader}
                    onClick={() => setActiveHeader('details')}
                >
                    Details
                </div>
                <div className={activeHeader === 'transactions' ? styles.eachSubHeaderActive : styles.eachSubHeader}
                    onClick={() => setActiveHeader('transactions')}
                >
                    Transactions
                </div>
                <div className={activeHeader === 'help' ? styles.eachSubHeaderActive : styles.eachSubHeader}
                    onClick={() => setActiveHeader('help')}
                >
                    Help
                </div>
            </div>
            <div>
                {activeHeader === "details" &&
                    <CardDetailsWrapper
                        cardDetails={cardDetails}
                    />
                }
                {activeHeader === "transactions" &&
                    <CardDetailsTransactions
                        cardIdentifier={cardIdentifier}
                    />
                }
            </div>
        </div>
    )
}

export default EachPurchasedCardDetails