import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import PriceListAddOns from '../../Components/PriceList/PriceListAddOns'
import PriceListEachCategory from '../../Components/PriceList/PriceListEachCategory'
import { BASE_URL_API } from '../../References/constants'
import styles from './PriceListHome.module.css'

const PriceListHome = () => {
    const { storeIdentifier } = useParams()
    const [priceList, setPriceList] = useState([])
    const [filteredPriceList, setFilteredPriceList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sectionIdentifier, setSectionIdentifier] = useState([
        {
            label: 'Female',
            identifier: 'SS1732115512',
        },
        {
            label: 'Male',
            identifier: 'SS1732115507',
        },
    ])
    const [activeSection, setActiveSection] = useState('SS1732115507')
    const [activeService, setActiveService] = useState()
    const [isAddOnActive, setIsAddOnActive] = useState(false)
    const onClose = () => {
        setIsAddOnActive(false)
    }
    const [cartItems, setCartItems] = useState([])
    const [isQuickAccessOpen, setIsQuickAccessOpen] = useState(false)
    // Store dynamic refs
    const categoryRefs = useRef({});

    window.onclick = event => event.target.id === "qaContainer" ? setIsQuickAccessOpen(false) : null
    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            setIsQuickAccessOpen(false)
        }
    });

    const getPriceList = async () => {
        const getPriceListResponse = await fetch(BASE_URL_API + "/getPriceList",
            {
                method: "POST",
                body: JSON.stringify({
                    storeIdentifier: ''
                })
            });

        if (!getPriceListResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getPriceListRespo = await getPriceListResponse.json()
            if (getPriceListRespo.status === "success") {
                setPriceList(getPriceListRespo.response || []);
                setFilteredPriceList(getPriceListRespo.response || []);
            } else {
                // alert('Something went wrong : Please re-try!')
                console.log(getPriceListRespo)
            }
        }
    }
    const activeServiceHandler = (k) => {
        setActiveService(k)
        setIsAddOnActive(true)
    }

    const filterPriceList = (query) => {
        if (!query.trim()) {
            setFilteredPriceList(priceList);
            return;
        }

        const lowercasedQuery = query.toLowerCase();

        const filtered = priceList?.filter((item) => {
            const matchesCategory = item.categoryName?.toLowerCase().includes(lowercasedQuery);
            const matchesService = item.services?.some((service) =>
                service.serviceName?.toLowerCase().includes(lowercasedQuery)
            );
            const matchesAddOns = item.services?.some((service) =>
                service.addOns?.some((addOn) =>
                    addOn.label?.toLowerCase().includes(lowercasedQuery)
                )
            );
            return matchesCategory || matchesService || matchesAddOns;
        });

        setFilteredPriceList(filtered);
    };

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        filterPriceList(query);
    };

    useEffect(() => {
        getPriceList()
    }, [])

    const addToCartHandler = (action, item, selectedAddOn) => {
        setIsAddOnActive(false)
        if (!item || typeof item !== "object" || Array.isArray(item)) {
            console.error("Invalid item provided");
            return;
        }

        // Initialize selectedAddOn to null if it's undefined or invalid
        selectedAddOn = selectedAddOn && typeof selectedAddOn === "object" && !Array.isArray(selectedAddOn)
            ? selectedAddOn
            : null;

        // Create a unique cart item combining the item and selectedAddOn
        const cartItem = {
            ...item,
            selectedAddOn,
        };

        setCartItems((prevCart) => {
            if (action === "add") {
                // Check if the item already exists in the cart with the same selectedAddOn
                const existingIndex = prevCart.findIndex(
                    (cartItem) =>
                        cartItem.identifier === item.identifier &&
                        JSON.stringify(cartItem.selectedAddOn) === JSON.stringify(selectedAddOn)
                );

                if (existingIndex >= 0) {
                    // If item exists, increase the count
                    const updatedCart = [...prevCart];
                    updatedCart[existingIndex].count = (updatedCart[existingIndex].count || 1) + 1;
                    return updatedCart;
                } else {
                    // If item doesn't exist, add as a new item with count = 1
                    return [...prevCart, { ...cartItem, count: 1 }];
                }
            } else if (action === "remove") {
                // Check if the item exists in the cart with the same selectedAddOn
                const existingIndex = prevCart.findIndex(
                    (cartItem) =>
                        cartItem.identifier === item.identifier &&
                        JSON.stringify(cartItem.selectedAddOn) === JSON.stringify(selectedAddOn)
                );

                if (existingIndex >= 0) {
                    const updatedCart = [...prevCart];
                    const existingItem = updatedCart[existingIndex];

                    if (existingItem.count > 1) {
                        // Decrease the count if it's greater than 1
                        updatedCart[existingIndex].count -= 1;
                    } else {
                        // Remove the item if count becomes 0
                        updatedCart.splice(existingIndex, 1);
                    }
                    return updatedCart;
                } else {
                    console.warn("Item not found in the cart to remove");
                    return prevCart;
                }
            } else {
                console.error("Invalid action specified");
                return prevCart;
            }
        });
    };


    const calculateCartTotals = (cartItems) => {
        if (!Array.isArray(cartItems)) {
            console.error("Invalid cartItems provided. Expected an array.");
            return { totalItems: 0, totalOriginalPrice: 0, totalDiscountedPrice: 0 };
        }

        let totalItems = 0;
        let totalOriginalPrice = 0;
        let totalDiscountedPrice = 0;

        cartItems.forEach((item) => {
            if (!item || typeof item !== "object" || Array.isArray(item)) {
                console.warn("Skipping invalid item in the cart", item);
                return;
            }

            // Ensure all values are numbers or default to 0
            const basePrice = parseFloat(item.basePrice) || 0;
            const offerPercentage = parseFloat(item.offerPercentage) || 0;
            const selectedAddOnOrgPrice = parseFloat(item.selectedAddOn?.orgPrice) || 0;
            const selectedAddOnDisPrice = parseFloat(item.selectedAddOn?.disPrice) || 0;
            const count = parseInt(item.count, 10) || 1;

            // Calculate original and discounted price
            const discountedBasePrice = basePrice - (basePrice * offerPercentage) / 100;

            totalItems += count;
            totalOriginalPrice += count * (basePrice + selectedAddOnOrgPrice);
            totalDiscountedPrice += count * (discountedBasePrice + selectedAddOnDisPrice);
        });

        return {
            totalItems,
            totalOriginalPrice: totalOriginalPrice.toFixed(2), // Keep 2 decimal places
            totalDiscountedPrice: totalDiscountedPrice.toFixed(2), // Keep 2 decimal places
        };
    };

    const scrollToCategory = (categoryIdentifier) => {
        setIsQuickAccessOpen(false)
        if (categoryRefs.current[categoryIdentifier]) {
            categoryRefs.current[categoryIdentifier].scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    {isAddOnActive === true && <PriceListAddOns isOpen={isAddOnActive} activeService={activeService} onClose={onClose} addToCartHandler={addToCartHandler} />}
                    {isQuickAccessOpen === true && (
                        <div className={styles.qaWrapper}>
                            <div className={styles.qaContainer} id="qaContainer" >
                                <div className={styles.qaContent}>
                                    {filteredPriceList?.length > 0 ? (
                                        filteredPriceList
                                            ?.filter((item) => item.genderIdentifier === activeSection)
                                            ?.map((itemPrice, indexPrice) => {
                                                if (itemPrice?.services?.length > 0) {
                                                    return (
                                                        <div key={indexPrice} className={styles.eachCategory}
                                                            onClick={() => scrollToCategory(itemPrice?.categoryIdentifier)}
                                                        >
                                                            <div>
                                                                {itemPrice?.categoryName}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                return null; // Handle cases where services are not present
                                            })
                                    ) : (
                                        <div className={styles.noResults}>No results found</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={styles.sectionWrapper}>
                        {sectionIdentifier?.map((item, index) => (
                            <div key={index}
                                className={activeSection === item?.identifier ? styles.eachSectionActive : styles.eachSection}
                                onClick={() => setActiveSection(item?.identifier)}
                            >
                                {item?.label}
                            </div>
                        ))}
                    </div>
                    <div className={styles.priceListWrapper}>
                        <div className={styles.priceListContainer}>

                            {filteredPriceList?.length > 0 ? (
                                filteredPriceList
                                    ?.filter((item) => item.genderIdentifier === activeSection)
                                    ?.map((itemPrice, indexPrice) => {
                                        if (itemPrice?.services?.length > 0) {
                                            return (
                                                <div
                                                    // ref={(el) => (categoryRefs?.current[itemPrice?.categoryIdentifier] = el)}
                                                    ref={(el) => {
                                                        if (el && itemPrice?.categoryIdentifier) {
                                                            categoryRefs.current[itemPrice.categoryIdentifier] = el;
                                                        }
                                                    }}

                                                >
                                                    <PriceListEachCategory
                                                        key={indexPrice}
                                                        itemPrice={itemPrice}
                                                        activeServiceHandler={activeServiceHandler}
                                                    />
                                                </div>
                                            );
                                        }
                                        return null; // Handle cases where services are not present
                                    })
                            ) : (
                                <div className={styles.noResults}>No results found</div>
                            )}

                        </div>

                    </div>
                    <div className={styles.footerWrapper}>
                        <div className={styles.menuActionWrapper}>
                            <div className={styles.searchWrapper}>
                                <input placeholder="Search for any service..."
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                            </div>
                            <div className={styles.menuWrapper}>
                                <button onClick={() => setIsQuickAccessOpen(true)} >Menu</button>
                            </div>
                        </div>
                        {cartItems?.length > 0 &&
                            <div className={styles.discountNextWrapper}>
                                <div className={styles.addedCount}>
                                    {calculateCartTotals(cartItems)?.totalItems} item(s) added!
                                </div>
                                <div className={styles.totalCount}>
                                    <div className={styles.totalOriginalPrice}>
                                        ₹{calculateCartTotals(cartItems)?.totalOriginalPrice}
                                    </div>
                                    <div className={styles.totalDiscountedPrice}>
                                        ₹{calculateCartTotals(cartItems)?.totalDiscountedPrice}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                </div>
            </div>

        </div>
    )
}

export default PriceListHome