import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import EachCard from '../../Components/GiftCards/EachCard'
import { BASE_URL_API } from '../../References/constants'
import AuthContext from '../../store/auth-context'
import styles from './GcHome.module.css'

const GcHome = () => {
    const authCtx = useContext(AuthContext)
    const [allCards, setAllCards] = useState([])
    const getAllCards = async () => {
        const getAllCardsResponse = await fetch(BASE_URL_API + "/getAllCards",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllCardsResponse.ok) {
            console.log(`Something went wrong : Server Error`)
        } else {
            const getAllCardsRespo = await getAllCardsResponse.json()
            if (getAllCardsRespo?.status === "success") {
                setAllCards(getAllCardsRespo?.response)
            } else {
                if (getAllCardsRespo?.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    setAllCards([])
                }
            }
            console.log(getAllCardsRespo?.response)
        }
    }

    const [faqsToAdd, setFaqsToAdd] = useState([
        {
            "question": "How much is the validity of this Gallant Card?",
            "answer": "It is valid for 365 days starting from the date of activation of this card."
        },
        {
            "question": "Can I share this card with my friends and family?",
            "answer": "No, you can’t share it with anyone except you. However, you can buy a new card for them."
        },
        {
            "question": "What services are included in this card?",
            "answer": "This card includes unlimited haircut services. The cardholder can avail any type of haircut, including advanced styles, as many times as they like."
        },
        {
            "question": "Is this card usable at one store only?",
            "answer": "No, you can use this card at all 'The Salon Company' stores nationwide, regardless of where it was purchased."
        },
        {
            "question": "I purchased this card online. How will I get a physical card?",
            "answer": "When you visit our stores for service, simply provide your phone number at reception, and our executives will issue you a physical card."
        },
        {
            "question": "How can I get a refund?",
            "answer": "This card is non-refundable."
        },
        {
            "question": "Can I use this card during the festive season, peak hours, or weekends?",
            "answer": "Absolutely! You can use this card anytime throughout the year, without restrictions. That’s why it’s truly unlimited."
        },
        {
            "question": "I want a haircut and beard trim. Can I pay only for the beard service while using my Gallant Card for the haircut?",
            "answer": "Yes, you can use your Gallant Card for a free haircut and pay only for other services like a beard trim."
        },
        {
            "question": "How can I check the validity of my card?",
            "answer": "You can visit our website at https://ltsc.lokaci.com to view your cards, packages, and pricelist. You can also buy new cards from there. Additionally, scan the QR code on the back of the physical card to get membership details."
        },
        {
            "question": "Can I transfer this card to my friend after using it for 6 months?",
            "answer": "No, it is not possible to transfer the card to another person."
        },
        {
            "question": "What if I lose my card?",
            "answer": "Don’t worry. Your records are securely stored in our cloud. Log in to our website or visit the nearest store to get a new physical card."
        },
        {
            "question": "Do I have to always carry this card to avail services?",
            "answer": "No, your card is linked to your phone number, so carrying it is not mandatory. However, having it in your wallet might make you feel like a VIP!"
        }
    ]
    )

    useEffect(() => {
        getAllCards()
    }, [])

    const addCardFaqs = async () => {
        const getAllCardsResponse = await fetch(BASE_URL_API + "/addCardFaqs",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    cardIdentifier: 'GALLANTSTORE0002',
                    faqs: JSON.stringify(faqsToAdd)
                })
            });

        if (!getAllCardsResponse.ok) {
            console.log(`Something went wrong : Server Error`)
        } else {
            const getAllCardsRespo = await getAllCardsResponse.json()
            if (getAllCardsRespo?.status === "success") {
                setAllCards(getAllCardsRespo?.response)
            } else {
                if (getAllCardsRespo?.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    setAllCards([])
                }
            }
            console.log(getAllCardsRespo?.response)
        }
    }


    return (
        <div className={styles.cardContainer}>
            {/* <div>
                <button onClick={() => addCardFaqs()}>Add</button>
            </div> */}
            {allCards?.map((item) => (
                <div key={item.ID} className={styles.card}>
                    <div className={styles.cardInner}>
                        {/* Front Side */}
                        <div
                            className={styles.cardFront}
                            style={{
                                backgroundImage: `url(${item.coverImage})`,
                                color: item.textColor,
                                backgroundColor: item.backgroundColor,
                            }}
                        >
                            <div className={styles.cardFrontWrapper}>
                                <h3>{item.label}</h3>
                                <p>{item.cardType.replace('_', ' ')}</p>
                            </div>
                        </div>

                        {/* Back Side */}
                        <div className={styles.cardBack}>
                            <h3>{item.label}</h3>
                            <p>{item.description}</p>
                            <p>
                                <strong>Price:</strong> ₹{item.toPay}
                            </p>
                            <p>
                                <strong>Worth Of:</strong> ₹{item.worthOff}
                            </p>
                            <Link to={`/buy/cards/${item.cardIdentifier}`} className={styles.buyButton}>
                                Buy Now
                            </Link>
                        </div>
                    </div>
                </div>
            ))}
        </div>

    )
}

export default GcHome