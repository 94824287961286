import React from 'react'
import styles from './BottomStackNavigator.module.css'
import { BiHome } from "react-icons/bi";
import { FaStore, FaUser } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { MdCardGiftcard, MdOutlineLocalOffer } from "react-icons/md";



const BottomStackNavigator = () => {
    return (
        <div className={styles.mainWrapper}>
            <Link to={`/home`}>
                <BiHome size={26} />
            </Link>
            <Link to={`/gift-cards`}>
                <MdCardGiftcard size={26} />
            </Link>
            <Link to={`/store0002/price-list`}>
                <MdOutlineLocalOffer size={26} />
            </Link>
            <Link to={`/stores`}>
                <FaStore size={26} />
            </Link>
            <Link to={`/profile`}>
                <FaUser size={24} />
            </Link>
        </div>
    )
}

export default BottomStackNavigator