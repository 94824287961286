import React, { useContext, useEffect, useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { MdLocalPhone } from "react-icons/md";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import EachFAQ from "../../Components/GiftCards/EachFAQ";
import { BASE_URL_API } from "../../References/constants";
import AuthContext from "../../store/auth-context";
import styles from "./BuyCard.module.css";

const BuyCard = () => {
    const authCtx = useContext(AuthContext)
    const { identifier } = useParams()
    const nav = useNavigate()

    const [cardDetails, setCardDetails] = useState({
        "ID": "1",
        "cardIdentifier": "GALLANTSTORE0001",
        "label": "GALLANT",
        "description": "Get unlimited free haircut for absolute 1 year.",
        "cardType": "TRUELY_UNLIMITED",
        "identifierDigits": "1000",
        "textColor": "#dfdfdf",
        "backgroundColor": "#616364",
        "toPay": "1099",
        "worthOff": "2250",
        "terms": "[\"Limited for one person use only.\"]",
        "coverImage": "https://ltsc.lokaci.com/visual-assets/giftcards/gallant-front.png",
        "duration": "365",
        "isShareable": "0",
    })

    const [paymentMessage, setPaymentMessage] = useState("");
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')

    const getTotalPrice = (k) => {
        const basePrice = parseInt(k) || 0; // Annual membership cost
        const gstRate = 0; // GST rate
        // const gstRate = 0.18; // GST rate
        const totalPrice = basePrice + basePrice * gstRate; // Price with GST
        return totalPrice
    }

    const getEachCardDetails = async () => {
        const getEachCardDetailsResponse = await fetch(BASE_URL_API + "/getEachCardDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    cardIdentifier: identifier
                })
            });

        if (!getEachCardDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getEachCardDetailsRespo = await getEachCardDetailsResponse.json()
            if (getEachCardDetailsRespo.status === "success") {
                setCardDetails(getEachCardDetailsRespo?.response)
            } else {
                if (getEachCardDetailsRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                }

            }
            console.log(getEachCardDetailsRespo)
        }
    }

    useEffect(() => {
        getEachCardDetails()
    }, [identifier])



    const handlePayment = () => {
        setPaymentMessage("Payment successful! Your gift card will be activated soon.");
        setTimeout(() => {
            setPaymentMessage(""); // Clear the message after 5 seconds
        }, 5000);
    };

    const makePayment = () => {
        if (authCtx.isLoggedIn) {
            // Create a form element
            const form = document.createElement("form");
            form.method = "POST";
            form.action = BASE_URL_API + "/getPayment.php";

            // Add form fields with the required data
            const data = {
                token: authCtx.token,
                firstName: firstName,
                lastName: lastName,
                email: email,
                productinfo: identifier,
                phone: authCtx?.userIdentifier,
                address1: "User address received",
                city: "city received",
                state: "state received",
                country: "",
                zipcode: "pincode received",
                udf1: "gender Male",
            };

            Object.entries(data).forEach(([key, value]) => {
                const input = document.createElement("input");
                input.type = "hidden"; // Hidden field to prevent display
                input.name = key;
                input.value = value;
                form.appendChild(input);
            });

            // Append the form to the body and submit it
            document.body.appendChild(form);
            form.submit(); // This triggers the POST request and navigates to the URL
        } else {
            nav('/login')
        }
    };

    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#ffffff");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#ffffff";
            document.head.appendChild(meta);
        }
    }, []);

    // console.log(cardDetails?.faqs)

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.headerWrapper}>
                <div className={styles.backWrapper}>
                    <Link to={`/gift-cards`}>
                        <BiChevronLeft size={32} />
                    </Link>
                </div>
                <div className={styles.label}>
                    {cardDetails?.label}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.cardWrapper}>
                    <div className={styles.card}>
                        <img
                            src={cardDetails?.coverImage}
                            alt="Gift Card"
                            className={styles.cardImage}
                        />
                    </div>
                </div>
                <div className={styles.cardInfo}>
                    <p>
                        {cardDetails?.description}
                    </p>
                    <ul>
                        {JSON.parse(cardDetails?.terms)?.map((itemTerms, indexTerms) => (
                            <li key={indexTerms}>
                                {itemTerms}
                            </li>
                        ))}
                    </ul>
                    <p className={styles.price}>Price: ₹{getTotalPrice(cardDetails?.toPay)}</p>
                    {paymentMessage && <p className={styles.paymentMessage}>{paymentMessage}</p>}
                </div>

                <div className={styles.ourStoresWrapper}>
                    <div className={styles.sectionTitle} >
                        Our Stores
                    </div>
                    <div className={styles.storeWrapper}>
                        <div className={styles.eachStoreWrapper} >
                            <div className={styles.detailsWrapper} >
                                <div className={styles.storeName} >
                                    The Salon Company
                                </div>
                                <div className={styles.storeAddress}>
                                    GT - 08, Sector - 117, Noida, 201307
                                </div>
                            </div>
                            <div className={styles.phoneWrapper}>
                                <a href="tel:+91-8800026046" target="_blank">
                                    <MdLocalPhone size={20} color="#007bff" />
                                </a>
                            </div>
                        </div>
                        <div className={styles.eachStoreWrapper} >
                            <div className={styles.detailsWrapper} >
                                <div className={styles.storeName} >
                                    Lokaci - The Salon Company
                                </div>
                                <div className={styles.storeAddress}>
                                    Shop No. 101, Eldeco Amantran, (Near Badami Restaurant), Sector - 119, Noida, 201307
                                </div>
                            </div>
                            <div className={styles.phoneWrapper}>
                                <a href="tel:+91-8766321368" target="_blank" >
                                    <MdLocalPhone size={20} color="#007bff" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.faqWrapper}>
                    <div className={styles.sectionTitle}>Frequently Asked Questions</div>
                    <div>
                        {cardDetails?.faqs?.map((item, index) => (
                            <EachFAQ key={index} item={item} />
                        ))}
                    </div>
                </div>


                <div className={styles.copyrightFooter}>
                    <div>
                        Powered by Stylelink Intelligence Services
                    </div>
                    <div>
                        A Lokaci Company
                    </div>
                    © 2024 Lokaci | All Rights Reserved
                </div>


            </div>

            <div className={styles.buyNowBtn}>
                <button onClick={() => makePayment()}>
                    Buy Now
                </button>
            </div>
        </div>
    );
};

export default BuyCard;
