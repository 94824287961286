import React, { useContext } from 'react'
import styles from '../Pages/Home.module.css'
import AuthContext from '../store/auth-context'

const Home = () => {
    const authCtx = useContext(AuthContext)
    return (
        <div className={styles.mainWrapper}>
            <div>

                Welcome to Lokaci - The Salon Company
            </div>
            <div className={styles.logoutWrapper}>
                {authCtx.isLoggedIn &&
                    <div>
                        <button onClick={() => authCtx.logout()}>
                            Logout
                        </button>
                    </div>
                }
            </div>
        </div>
    )
}

export default Home